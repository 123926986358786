$(document).ready(function () {

    var touchMoved;

    //Constant class
    var CHILD_OF_DROPDOWN = '.nav__item.is-dropdown > .nav__link';
    var IS_DROPDOWN = '.nav__item.is-dropdown';
    var IS_DROPDOWN_IS_ACTIVE = '.nav__item.is-dropdown.is-active';

    // Touch Device Detection - when testing make sure you are in Responsive mode NOT screen width.
    var isTouchDevice = 'ontouchstart' in document.documentElement;

    $('.nav').on('click tap', CHILD_OF_DROPDOWN, function (e) {
        if ($(window).innerWidth() < 991) {
            e.preventDefault();
            if (touchMoved === true) return; // If scrolling

            var $submenu = $(this).closest(IS_DROPDOWN);
            var $currentOpenSubmenus = $(IS_DROPDOWN_IS_ACTIVE);

            if ($submenu.hasClass('is-active')) {
                var openItemCount = $(IS_DROPDOWN_IS_ACTIVE).length;
                if (openItemCount <= 1) {
                    closeNav();
                }
            } else {
                $('.nav').addClass('is-active');
                $('body').addClass('mobileNav-open');
                $submenu.addClass('is-active');
            }
        }
    }).on('touchmove', function () { touchMoved = true; }).on('touchstart', function () { touchMoved = false; });

    $('.nav__back').on('click', function () {
        $(this).closest(IS_DROPDOWN_IS_ACTIVE).removeClass('is-active');
    });

    $('.nav_overlay').on('click', function () {
        closeNav();
    });

    $('.mobileNav__toggle').on('click', function (e) {
        e.preventDefault();
        var $hamburger = $('.hamburgerToggle__icon');

        $('.nav').addClass('is-active');
        $('body').addClass('mobileNav-open');
        $hamburger.toggleClass('icon-change');

        if ($hamburger.hasClass('icon-change') === false) {
            closeNav();
        }

        
    });

    setMobileActiveLevel();
});

function closeNav() {
    $('.nav, .nav__item.is-dropdown').removeClass('is-active');
    $('.mobileNav__toggle').removeClass('icon-change');
   
    setTimeout(function () {
        $('body').removeClass('mobileNav-open');
    }, 600);
}


function setMobileActiveLevel() {
    $(".flyout").find(".nav__link--active").parents('.nav__item.is-dropdown').addClass("is-active");
}
