//= include _appius.utilities.js

//= include _appius.mobileNav.js
//= include _appius.tabletNav.js
//= include vendor/_jquery.cookiebar.js
//= include vendor/slick/slick.min.js

(function($) {
    $(document).ready(function () {
  
        initCookieBar();
        modalSlider(); 
        
    });
})(jQuery);

// Setup cookie bar and pull in translation text

function initCookieBar() {
    var cookieBarText = 'Viridor uses cookies to give you the best possible online experience. By continuing to browse this site you are agreeing to our use of cookies. See our <a href="https://viridor.co.uk/vanity-urls/footers/privacy-and-cookies/">cookie policy</a> for more information';

        $.cookieBar({
            message: cookieBarText,
            acceptButton: true
        });
}


//When modal loads initialise slick otherwise spacing issues

function modalSlider() {

    var CAROUSEL_SELECTOR = '.carousel__slick';

    $('.modal').on('hide.bs.modal', function () {
        $(CAROUSEL_SELECTOR).slick('destroy');
    });

    $('.modal').on('shown.bs.modal', function () {

        $(CAROUSEL_SELECTOR).slick({
            lazyLoad: 'ondemand',
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            touchThreshold: 10,
            draggable: true,
            autoplay: false,
            speed: 1200,
            lazyLoadBuffer: 1,
            dots: true,
            focusOnSelect: true,
            arrows: true,
        });
    });   
}

//Scroll to section

jQuery(document).ready(function ($) {
    
    function scrollToSection(event) {
        event.preventDefault();

        var $section = $($(this).attr('href'));
 
        $('html, body').animate({
            scrollTop: $section.offset().top

        }, 500);
        //calls closeNav function from mobileNav
        closeNav();
    }

    var $anchorLink = $('a[href^="#"]');
    $($anchorLink).on('click', scrollToSection);


    //Jump to next section

    $('.btn--nextStep').click(function () {
  
        var $this = $(this),
        $nextSection = $this.parents('section').next();

        $('html, body').animate({
            scrollTop: $nextSection.offset().top

        }, 500)
    });

}(jQuery));

(function () {
    $(document).ready(function () {
        var navActiveScroll = debounce(function () {
            var scrollDistance = $(window).scrollTop() - 50;// + $('.header').height() + 66; // Add roughly 200px
            var closestDistance = Infinity;
            var $closestElem = null;
            var closestID = -1;
            // Assign active class to nav links while scrolling
            $('.detailSection').each(function (i) {
                var $this = $(this);

                var delta = Math.abs(scrollDistance - $this.position().top);
                if (delta < closestDistance) {
                    closestDistance = delta;
                    $closestElem = $this;
                    closestID = i;
                }
            });

            // Set the closest
            $('.nav__link--active').removeClass('nav__link--active');
            $('.nav__link').eq(closestID).addClass('nav__link--active');

            $('.detailSection--active').removeClass('detailSection--active');
            $closestElem.addClass('detailSection--active');
        }, 50);

        $(window).scroll(function () { navActiveScroll(); });
        navActiveScroll();
    });

    function debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this, args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }
})(jQuery);