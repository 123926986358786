function SlideDownElement(element) {
    $(element).slideDown().addClass('is-visible');
}


function SlideUpElement(element) {
    $(element).slideUp().removeClass('is-visible');
}


//----------------------------------------------------------------------------------------------


/**
 * @description Gets a list of query strings from the Url.
 * @return {array}
 */
function GetQueryStrings() {
    var queryStrings = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        queryStrings.push(hash[0]);
        queryStrings[hash[0]] = hash[1];
    }

    return queryStrings;
}


/**
 * @description Gets the value of a query string parameter
 * @param {string} Name of the query string parameter
 * @return {string}
 */
function GetQueryString(name) {
    return GetQueryStrings()[name];
}


//----------------------------------------------------------------------------------------------


/**
 * @description Swaps text around in elements (typically buttons). data-current-state="" and data-next-state="" must be on the element for this to work.  
 */
function SwapButtonStates(element) {
    var currentState = element.data("current-state");
    var nextState = element.data("next-state");
    console.log(currentState);
    console.log(nextState);

    //Swap the buttons text around between 'more' and 'less'
    element.html(element.html().replace(currentState, nextState));
    element.data("current-state", nextState);
    element.data("next-state", currentState);
}


//----------------------------------------------------------------------------------------------