(function ($) {

    var MIN_BREAKPOINT = 768;
    var MAX_BREAKPOINT = 1200;
    var DROPDOWN_LI = '.nav__item--1.is-dropdown';

    $(document).ready(function () {
        if (window.innerWidth >= MIN_BREAKPOINT && window.innerWidth < MAX_BREAKPOINT) {
            $(DROPDOWN_LI).each(function (elem, index) {
                var $menu = $(this).find('.nav__list--2');
                var $span = $(this).find('> span.flyout__visible');
                var $link = $(this).find('> a.nav__link--1');
                var label = $link.html();

                var href = $link.attr('href');

                $menu.prepend('<li class="nav__item  nav__item--tabletSwapSpan nav__item--2"><a class="nav__link nav__link--2" href="' + href + '">' + label + '</a></li>');
                $link.addClass('flyout__hidden--sm-md');
                $span.addClass('flyout__visible--sm-md');
            });
        }

        init();
    });

    function init() {

        $(DROPDOWN_LI).hover(function mouseEnterDropdown(e) {
            var $this = $(this);
            setTimeout(function () {
                if (window.innerWidth > 768)
                    $this
                      .addClass('open')
                      .find('.dropdown-toggle')
                      .attr('aria-expanded', true);
            }, 5);
        }, function mouseLeaveDropdown(e) {
            var $this = $(this);

            setTimeout(function () {
                if (window.innerWidth > 768)
                    $this
                      .removeClass('open')
                      .find('.dropdown-toggle')
                      .attr('aria-expanded', false);
            }, 5);
        });
    }

})(jQuery);